import { ContactFormFoundFrom } from "../ContactFormFoundFrom.js";
import { Locale, type LocalizedString } from "../Locale.js";

export const contactFormFoundFromNameMap: Record<ContactFormFoundFrom, LocalizedString> = {
	[ContactFormFoundFrom.LinkedIn]: {
		[Locale.cs]: "LinkedIn",
	},
	[ContactFormFoundFrom.WebSearch]: {
		[Locale.cs]: "Vyhledávání na internetu",
	},
	[ContactFormFoundFrom.Conference]: {
		[Locale.cs]: "Konference",
	},
	[ContactFormFoundFrom.Recommendation]: {
		[Locale.cs]: "Doporučení",
	},
};
