<script lang="ts">
	import type { HTMLInputAttributes, HTMLLabelAttributes } from "svelte/elements";
	import Icon from "../Icon.svelte";
	import check from "../../../../core/assets/icons/check.svg?raw";
	import cross from "../../../../core/assets/icons/cross.svg?raw";
	import chevron from "../../../../core/assets/icons/arrow-down.svg?raw";
	import clock from "../../../../core/assets/icons/clock.svg?raw";
	import { createEventDispatcher } from "svelte";

	export let id: string;
	export let type: HTMLInputAttributes["type"] = "text";
	export let value: HTMLInputAttributes["value"] = "";
	export let name = id;
	export let placeholder = " ";
	export let required = false;
	let formControlClass = "";
	export { formControlClass as class };
	export let inputClass = "";
	export let errorMessage = "";
	export let showErrorMessage = false;
	export let input: HTMLInputElement | null = null;
	export let minlength: HTMLInputAttributes["minlength"] | undefined = undefined;
	// "off" does not work properly, random strings do.
	export let autocomplete: Exclude<HTMLInputElement["autocomplete"], "off"> | "nope" = "nope";
	export let fullWidth = false;
	export let disabled = false;
	export let clearable = false;
	export let alwaysEnableClear = false;
	export let showValid = false;
	export let withoutLabel = false;

	export let labelProps: Omit<HTMLLabelAttributes, "id"> = {};
	export let inputProps: Omit<
		HTMLInputAttributes,
		"id" | "placeholder" | "type" | "required" | "name" | "autocomplete" | "disabled"
	> = {};

	const dispatch = createEventDispatcher();

	let hasValue = Boolean(value);
	function onInput({ target }: Event): void {
		const input = target as HTMLInputElement;
		hasValue = Boolean(input.value);
	}

	function isInputValid(input: HTMLInputElement): boolean {
		return hasValue && input.checkValidity();
	}

	$: inputValid = input && isInputValid(input);
	function onChange({ target }: Event): void {
		const input = target as HTMLInputElement;
		hasValue = Boolean(input.value);
		inputValid = isInputValid(input);
		({ value } = input);
	}

	function onClear(): void {
		if (input) {
			input.value = "";
			input.dispatchEvent(new Event("input", { bubbles: true }));
			input.dispatchEvent(new Event("change", { bubbles: true }));
			({ value } = input);
		}
		dispatch("clear");
	}

	function increment(): void {
		if (input) {
			input.stepUp();
			({ value } = input);
			input.dispatchEvent(new Event("input", { bubbles: true }));
			input.dispatchEvent(new Event("change", { bubbles: true }));
		}
	}

	function decrement(): void {
		if (input) {
			input.stepDown();
			({ value } = input);
			input.dispatchEvent(new Event("input", { bubbles: true }));
			input.dispatchEvent(new Event("change", { bubbles: true }));
		}
	}

	$: isValid = inputValid && !showErrorMessage;

	// eslint-disable-next-line no-warning-comments
	// FIXME remove this after upgrade to Svelte 5.
	/* eslint-disable @typescript-eslint/explicit-function-return-type */
</script>

<div class="relative {formControlClass}">
	<div class="flex flex-col gap-1" class:w-full={fullWidth}>
		<div
			role="button"
			tabindex="-1"
			class="{inputClass} placeholder-shown:shadow-input placeholder-shown:border-gray relative flex w-full min-w-[7rem] cursor-text overflow-hidden border-[0.0625rem] bg-white text-xs leading-8 text-black placeholder-shown:bg-gray-50 focus:bg-white focus:outline-none lg:w-auto {withoutLabel
				? 'h-[2.5rem] lg:h-[2.8125rem]'
				: 'h-[2.75rem]'}"
			class:shadow-inputError={showErrorMessage}
			class:shadow-inputActive={!showErrorMessage}
			class:focus:shadow-inputError={showErrorMessage}
			class:focus:shadow-inputActive={!showErrorMessage}
			class:border-danger={showErrorMessage}
			class:border-gray={!showErrorMessage}
			on:click={() => {
				input?.focus();
			}}
			on:keyup={() => {
				// ignore
			}}
		>
			<input
				bind:this={input}
				{id}
				{type}
				{name}
				{required}
				{placeholder}
				{autocomplete}
				{disabled}
				{minlength}
				readonly={disabled || inputProps.readonly}
				value={value ?? ""}
				{...inputProps}
				class="input peer {clearable && showValid && isValid ? 'pr-14 lg:pr-16' : ''} {clearable &&
				!(showValid && isValid)
					? 'pr-10 lg:pr-12'
					: ''} {inputProps.class} {!withoutLabel ? 'input-with-label' : ''} {inputProps.class}"
				on:change={onChange}
				on:input={onInput}
				on:change
				on:input
				on:invalid
			/>
			{#if $$slots.default}
				<label for={id} {...labelProps} class="label {labelProps.class}">
					<slot />{#if required}<span>&nbsp;*</span>{/if}
				</label>
			{/if}
			{#if type === "number"}
				<div
					class="pointer-events-auto absolute bottom-0 right-[.6rem] top-0 flex cursor-default flex-col justify-center lg:right-[1.2rem]"
				>
					<button
						class="outline-none focus:outline-none active:outline-none"
						type="button"
						on:click={increment}
						aria-label="Navýšit číslo"
					>
						<Icon icon={chevron} class="h-5 rotate-180" />
					</button>
					<button
						class="outline-none focus:outline-none active:outline-none"
						type="button"
						on:click={decrement}
						aria-label="Snížit číslo"
					>
						<Icon icon={chevron} class="h-5" />
					</button>
				</div>
			{/if}
			{#if type === "time"}
				<Icon icon={clock} class="pointer-events-none absolute bottom-0 right-3 top-0 my-auto h-5 lg:right-6" />
			{/if}
			<div class="absolute bottom-0 right-3 top-0 my-auto flex h-full items-center gap-[.4rem] lg:right-6">
				{#if showValid && isValid}
					<Icon icon={check} class="text-tertiaryGreen aspect-square h-3" />
				{/if}
				{#if clearable}
					<button type="button" on:click={onClear} disabled={!alwaysEnableClear && !hasValue}>
						<Icon
							icon={cross}
							class="text-gray-250 aspect-square h-3 cursor-pointer transition-colors duration-300 hover:text-gray-300"
						/>
					</button>
				{/if}
			</div>
		</div>
		{#if showErrorMessage}
			<span class="text-danger px-6 text-[0.8125rem] leading-[1.25]">{errorMessage}</span>
		{/if}
	</div>
</div>
