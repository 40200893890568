<script lang="ts">
	import Icon from "../Icon.svelte";
	import chevronDown from "../../../../core/assets/icons/arrow-down.svg?raw";

	interface Option {
		label: string;
		selected?: boolean;
		value: string | null;
	}

	export let ariaLabel: string | undefined = undefined;
	export let disabled = false;
	export let id: string | undefined = undefined;
	export let options: Option[] = [];
	export let required = false;
	export let value: unknown = undefined;
	let selectClass = "";
	export { selectClass as class };
	export let iconClass = "";

	export function reset(): void {
		value = null;
	}
</script>

<div
	class="border-gray font-size-[14px] relative flex h-[2.75rem] w-full min-w-[7rem] overflow-hidden border-[0.0625rem] border-b-0 bg-white text-xs leading-8 text-black focus:bg-white focus:outline-none {selectClass}"
>
	<Icon
		class="pointer-events-none absolute bottom-0 right-2 top-0 my-auto h-5 lg:right-5 {iconClass}"
		icon={chevronDown}
	/>
	<select
		aria-label={ariaLabel}
		{disabled}
		{id}
		{required}
		bind:value
		class="w-full appearance-none bg-white px-4 py-2 text-xs outline-0"
		name={id}
		on:change
	>
		{#each options as option}
			<option value={option.value} selected={option.selected}>{option.label}</option>
		{/each}
	</select>
</div>
