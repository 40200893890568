<script lang="ts">
	import type { HTMLInputAttributes, HTMLLabelAttributes } from "svelte/elements";

	export let id: string;
	export let checked = false;
	export let name = id;
	export let required = false;
	let formControlClass = "";
	export { formControlClass as class };
	export let inputClass = "";
	export let errorMessage = "";
	export let showErrorMessage = false;
	export let disabled = false;

	export let labelProps: Omit<HTMLLabelAttributes, "id"> = {};
	export let inputProps: Omit<HTMLInputAttributes, "id" | "placeholder" | "type" | "required" | "name"> = {};
	export let input: HTMLInputElement | null = null;
</script>

<div class="mt-2 flex gap-3 {formControlClass}">
	<div class="flex items-start gap-2">
		<input
			bind:this={input}
			class="{inputClass} checked:before:bg-tertiary peer relative mt-[.17rem] h-3.5 w-3.5 shrink-0 appearance-none rounded-sm border border-gray-100 !bg-white before:absolute before:inset-0 before:m-auto before:block before:h-[7px] before:w-[7px] before:shrink-0 before:rounded-sm before:transition-colors before:duration-200 before:content-[''] after:relative after:block after:h-full after:w-full after:rounded-sm after:content-['']"
			{id}
			{required}
			bind:checked
			{disabled}
			{name}
			{...inputProps}
			on:change
			on:input
			type="checkbox"
		/>
		<label class="text-neutral text-xs tracking-[0.018rem] lg:text-base" for={id} {...labelProps}>
			<slot />
		</label>
	</div>
	{#if showErrorMessage}
		<span class="text-danger px-6 text-[0.8125rem] leading-[1.25]">{errorMessage}</span>
	{/if}
</div>
