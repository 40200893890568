<script lang="ts">
	let headlineClass = "";
	export { headlineClass as class };

	export let h1 = false;
</script>

{#if h1}
	<h1
		class="text-primary m-0 pt-0 text-center text-[1.375rem] font-bold uppercase tracking-[0.3em] md:text-[2rem] {headlineClass}"
	>
		<slot />
	</h1>
{:else}
	<h2
		class="text-primary m-0 text-center text-[1.375rem] font-bold uppercase tracking-[0.3em] md:text-[2rem] {headlineClass}"
	>
		<slot />
	</h2>
{/if}
