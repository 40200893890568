<script lang="ts">
	import LabelledInput from "./Form/LabelledInput.svelte";
	import Checkbox from "./Form/Checkbox.svelte";
	import DecoratedHeadline from "./DecoratedHeadline.svelte";
	import Button from "./Button.svelte";
	import { gdprPathMap } from "../../../core/schema/paths/gdprPathMap.js";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import { createPromiseStore } from "../../../core/utils/createPromiseStore.js";
	import type { ContactFormInput } from "../../../core/schema/ContactForm.js";
	import sendContactForm from "../appsync/mutations/sendContactForm.graphql";
	import { mutation } from "../appsync/graphql.js";
	import Form from "./Form/Form.svelte";
	import Modal from "./Modal.svelte";
	import { nbspify } from "../../../core/utils/nbspify.js";
	import { contactFormFoundFromNameMap } from "../../../core/schema/names/contactFormFoundFromNameMap";
	import type { ContactFormFoundFrom } from "../../../core/schema/ContactFormFoundFrom";
	import Select from "./Form/Select.svelte";

	let containerClass = "";
	export { containerClass as class };
	export let showText = true;

	const globalSettings = getGlobalSettings();
	const { TENANT_LOCALE, TENANT_ID } = globalSettings;

	let textArea: HTMLTextAreaElement | undefined;
	let textAreaValue = "";
	let hasScrollbar = false;

	function checkForScrollbar(): void {
		if (textArea) {
			hasScrollbar = textArea.scrollHeight > textArea.clientHeight;
		}
	}

	const send = createPromiseStore(async (input: ContactFormInput) => {
		await mutation(globalSettings.PUBLIC_GRAPHQL_ENDPOINT, sendContactForm, { input });
	});

	let showSuccessModal = false;

	let resetSelect: () => void;

	function resetForm(form: HTMLFormElement): void {
		form.reset();
		resetSelect();
	}

	async function submit(event: SubmitEvent): Promise<void> {
		const form = event.target as HTMLFormElement;
		const formData = new FormData(form);
		const foundFrom = formData.get("foundFrom") as ContactFormFoundFrom | undefined;
		const input: ContactFormInput = {
			companyName: String(formData.get("company")),
			email: String(formData.get("email")),
			firstName: String(formData.get("firstName")),
			gdprApprovedAt: new Date().toISOString(),
			message: String(formData.get("message")),
			pageUrl: window.location.href,
			phoneNumber: String(formData.get("phoneNumber")),
			surname: String(formData.get("surname")),
			tenantId: TENANT_ID,
			...(Boolean(foundFrom) && { foundFrom }),
		};
		await send.call(input);
		resetForm(form);
		showSuccessModal = true;
	}
</script>

<Modal show={showSuccessModal}>
	<p class="h2" slot="title">Děkujeme za zprávu</p>
	<div class="flex flex-col items-center gap-2">
		<p>Vaše zpráva byla úspěšně odeslána. Brzy se vám ozveme.</p>

		<Button
			class="px-6"
			on:click={() => {
				showSuccessModal = false;
			}}
		>
			Rozumím
		</Button>
	</div>
</Modal>

<div class="container flex w-full flex-col items-center gap-16 md:w-3/4 {containerClass}">
	<DecoratedHeadline>{nbspify("Kontaktujte nás")}</DecoratedHeadline>
	<div class="flex w-full flex-col justify-center gap-9 lg:flex-row">
		{#if showText}
			<div class="flex flex-col gap-6 lg:max-w-[26.25rem]">
				<p class="text-bito-black text-[1.5rem] font-extrabold">
					{nbspify("Máte dotaz nebo potřebujete poradit? Napište nám!")}
				</p>
				<p class="text-xs text-gray-400">
					{nbspify(
						"Ať už řešíte jakýkoli problém se skladovacími prostory, naši BITO specialisté vám pomohou. Potřebujete poradit s výběrem regálů, skladovacích systémů nebo máte specifický dotaz? Stačí vyplnit formulář nebo zavolat – společně najdeme to nejlepší řešení.",
					)}
				</p>
			</div>
		{/if}
		<Form on:submit={submit} class="w-full max-w-[42rem]">
			<div class="flex flex-col sm:flex-row">
				<LabelledInput
					id="firstName"
					required
					class="w-full"
					inputClass="h-[4.5rem] sm:border-r-0 sm:border-b border-b-0"
					placeholder="Vyplňte své jméno"
				>
					Jméno
				</LabelledInput>
				<LabelledInput
					id="surname"
					class="w-full"
					required
					placeholder="Vyplňte své přijmení"
					inputClass="h-[4.5rem] sm:border-b border-b-0"
				>
					Přijmení
				</LabelledInput>
			</div>
			<div class="flex flex-col sm:flex-row">
				<LabelledInput
					id="email"
					type="email"
					class="w-full"
					required
					placeholder="Zadejte svůj e-mail"
					inputClass="h-[4.5rem] sm:border-y-0 sm:border-r-0">E-mail</LabelledInput
				>
				<LabelledInput
					id="phoneNumber"
					type="tel"
					class="w-full"
					required
					inputClass="h-[4.5rem] border-y-0"
					placeholder="Zadejte své telefonní číslo">Telefon</LabelledInput
				>
			</div>
			<LabelledInput required id="company" inputClass="h-[4.5rem] border-b-0" placeholder="Zadejte název své firmy">
				Název firmy
			</LabelledInput>

			<div class="relative">
				<div class="flex flex-col gap-1">
					<Select
						ariaLabel="Odkud jste přišli?"
						id="foundFrom"
						bind:reset={resetSelect}
						options={[
							{ value: null, label: "Odkud jste přišli?" },
							...Object.entries(contactFormFoundFromNameMap).map(([value, label]) => ({
								label: label[TENANT_LOCALE],
								value,
							})),
						]}
					/>
				</div>
			</div>

			<div class="border-gray group relative border">
				<textarea
					id="message"
					name="message"
					bind:this={textArea}
					bind:value={textAreaValue}
					on:input={checkForScrollbar}
					placeholder="Napište svou zprávu"
					class="placeholder:text-gray-250 w-full resize-none px-4 text-xs font-semibold {!hasScrollbar &&
						'pt-9'} placeholder:text-xs placeholder:font-[500] focus:outline-none"
					rows="5"
					required
				/>
				{#key textAreaValue}
					<span
						class="group-focus-within:text-4xs group-focus-within:top-2 {textAreaValue !== '' &&
							'!text-4xs !top-2'} absolute left-4 top-3 text-xs transition-all duration-200 {hasScrollbar && 'hidden'}"
						>Zpráva *</span
					>
				{/key}
			</div>
			<div class="flex flex-col justify-between gap-3 sm:flex-row sm:items-center">
				<Checkbox
					required
					id="gdprApprovedAt"
					inputClass="w-[1.5rem] h-[1.5rem] !mt-0 before:!h-[.875rem] before:!w-[.875rem]"
				>
					<span class="text-2xs text-bito-black">
						{nbspify("Souhlasím se")}
						<a href="/{gdprPathMap[TENANT_LOCALE]}" class="underline">
							{nbspify("Zásadami zpracování osobních údajů")}
						</a>
					</span>
				</Checkbox>
				<Button variant="primary" type="submit" class="w-[7.5rem] sm:w-[13.75rem]">Odeslat</Button>
			</div>
		</Form>
	</div>
</div>
